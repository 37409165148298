import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import HowWeWork from "@containers/global/how-we-work";
import LanguageGuidesOne from "@containers/language-guides/layout-3";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import FaqArea from "@containers/faq/layout-03";
import QuoteForm from "@containers/translation-quote-form";
const BlockchainFintechPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Blockchain &amp; Fintech Translation Services by Andovar" description="Innovate your way to global market success with our state-of the art Cryptocurrency and Fintech Translation Services! Contact us for more information"
      />
    
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["cryptocurrency-fintech-page-header-section"]} />
        <QuoteForm />
        <FeatureGrid data={content["cryptocurrency-fintech-features"]} />
        <IntroAreaReverse data={content["cryptocurrency-fintech-intro"]} />
        <LanguageGuidesOne layout={4} data={content["cryptocurrency-fintech-what-we-translate-body"]} />
        <HowWeWork layout={2} data={content["howwework"]} />
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["cryptocurrency-fintech-faq-body"]} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query BlockchainFintechPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "cryptocurrency-fintech-translation-services" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

BlockchainFintechPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default BlockchainFintechPage;
